import { website } from '@/axios'

export default {
  // acceptMerchent (storeId, payload) {
  //   return marketPlace().patch(`admin/stores/${storeId}`, payload)
  // },
  getEvent (id) {
    return website().get(`/admin/events/${id}`)
  },
  editEvent (id, payload) {
    return website().put(`/admin/events/${id}`, payload)
  },
  editMostEvent (id, payload) {
    return website().put(`admin/events/most-watched/${id}`, payload)
  },
  addEvent (payload) {
    return website().post('admin/events', payload)
  },
  addMost (payload) {
    return website().post('admin/events/most-watched', payload)
  },
  uploadImage (payload) {
    return website().post('upload', payload)
  }
}
